/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/* FUNCTIONS DEFINITIONS */

/* Detect IE
------------------------------------------------------------------------------*/
function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    // other browser
    return false;
}


function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function setLocation(clocation, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    var $path = "path=/";
    document.cookie = clocation + "=" + cvalue + "; " + expires + "; " + $path;

}

function getLocation(clocation) {
    var location = clocation + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(location) === 0) {
            return c.substring(location.length, c.length);
        }
    }
    return "";
}

function parseQuery() {
    var qstr = window.location.search;
    var query = {};
    var a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
    for (var i = 0; i < a.length; i++) {
        var b = a[i].split('=');
        var key = decodeURIComponent(b[0]);
        var value = decodeURIComponent(b[1] || '');
        if (query[key] !== undefined) {
            if (Array.isArray(query[key])) {
                query[key].push(value);
            } else {
                query[key] = [query[key], value];
            }
        } else {
            // For non AHF explore types
            if (key.match(/_type$/)) {
                if (value.indexOf("+") !== -1) {
                    query[key] = value.split('+');
                } else if (value.indexOf(" ") !== -1) {
                    query[key] = value.split(' ');
                } else {
                    query[key] = [value];
                }
            } else {
                switch (key) {
                    // Fix mixed - and _ versions
                    case 'ahf_region':
                        query['ahf-region'] = value;
                        break;
                    case 'region_lat':
                        query['region-lat'] = value;
                        break;
                    case 'region_lng':
                        query['region-lng'] = value;
                        break;
                    default:
                        query[key] = value;

                }
            }
        }
    }
    return query;
}

function checkLocation() {
    var $userloc = getLocation("nta_pref_location");
    if ($userloc !== "") {
        jQuery("body").addClass("loc-" + $userloc);
        var $dataTrack = jQuery("body").attr("data-track");

        if ($dataTrack !== $userloc) {
            jQuery("body").addClass('new-loc');
            /*jQuery('a#select-state').click();*/
        } else {
            jQuery("body").removeClass('new-loc');
        }

        var $homeHref = jQuery('a.brand.nta-logo').attr('href');
        if ($userloc !== 'national') {
            jQuery('a.brand.nta-logo').attr('href', $homeHref + $userloc);
        }
    }
}

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

var $map_loaded = 0;
var $clustered = 0;
var map;

(function ($) {

    function add_marker($marker, map) {

        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        // add to array
        map.markers.push(marker);


        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function () {

                infowindow.open(map, marker);

            });
        }

    }


    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type  function
     *  @date  8/11/2013
     *  @since 4.3.0
     *
     *  @param map (Google Map object)
     *  @return  n/a
     */

    function center_map(map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        }
        else {
            // fit to bounds
            map.fitBounds(bounds);
            // Add a marker clusterer to manage the markers.

        }

    }

    /*
     *  render_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type  function
     *  @date  8/11/2013
     *  @since 4.3.0
     *
     *  @param $el (jQuery element)
     *  @return  n/a
     */


    function render_map($el, $c, $z, lat, lng) {

        if (!$el) {
            return;
        }
        var $markers = $el.find('.marker');

        if (lat == undefined || !lat) {
            lat = -25.0326455
        }
        if (lng == undefined || !lng) {
            lng = 115.226784
        }

        var styles = [
            { "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#38353c" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }
        ];

        var styledMap = new google.maps.StyledMapType(styles,
            { name: "Styled Map" });
        var args;
        if (!$c) {
            args = {
                zoom: 10,
                center: new google.maps.LatLng(lat, lng),
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                }
            };
        } else {
            args = {
                zoom: $z,
                center: new google.maps.LatLng(lat, lng),
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                }
            };
        }
        // create map
        map = new google.maps.Map($el[0], args);

        //Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');

        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {
            add_marker($(this), map);
        });

        // center map
        if (!$c) {
            center_map(map);
        } else {
            map.setCenter($c);
            map.setZoom($z);
        }

        // Functions to fire when map is dragged or zoomed, for AHF explore only.
        if ($('#ahf-explore-btns').length > 0) {
            var markerCluster = new MarkerClusterer(map, map.markers,
                { imagePath: '/wp-content/themes/nt_theme/dist/images/m' });

            google.maps.event.addListener(map, 'dragend', function () {
                updateAHFMarkers(map);
            });
            google.maps.event.addListener(markerCluster, 'clusterclick', function () {
                $clustered = 1;
            });

            google.maps.event.addListener(map, 'zoom_changed', function (e) {
                if ($map_loaded && !$clustered) {
                    updateAHFMarkers(map);
                }
            });
            google.maps.event.addListener(map, 'idle', function () {
                $map_loaded = 1;
                $clustered = 0;
            });
        }


    }

    window.render_map = render_map();

    waitUpdate = null;
    xhrUpdate = null;

    function updateAHFMarkers(map) {
        if (arguments.length == 1) {
            if (waitUpdate != null) {
                clearTimeout(waitUpdate);
            }
            waitUpdate = setTimeout(function () {
                updateAHFMarkers(map, true)
            }, 1000);
            return;
        }
        var bounds = map.getBounds();
        var ne = bounds.getNorthEast();
        var neLat = ne.lat();
        var neLng = ne.lng();
        var sw = bounds.getSouthWest();
        var swLat = sw.lat();
        var swLng = sw.lng();
        var center = bounds.getCenter();
        var zoom = map.getZoom();
        var $data = {
            'action': 'ahfmap',
            'neLat': neLat,
            'neLng': neLng,
            'swLat': swLat,
            'swLng': swLng,
        };
        var $params = ['ahfdate_search', 'ahfdate-search', 'ahf_type', 'keyword'];
        var $paramsData = [];
        $.each($params, function (i) {
            if (getUrlParameter($params[i])) {
                $paramsData[i] = getUrlParameter($params[i]);
            }
        });
        if ($paramsData.length > 0) {
            $.each($paramsData, function (i) {
                $data[$params[i]] = $paramsData[i];
            });
        }
        if (xhrUpdate != null) {
            xhrUpdate.abort();
        }
        xhrUpdate = $.ajax({
            type: 'POST',

            url: ahfAjax.ajaxurl,
            data: $data,
            success: function (data) {
                $('#explore-content-wrapper').parent().addClass('with-map');
                data = $('<div/>').append(data);
                $('#ahf-map').hide().html($('#ahf-map2', data)).show();
                render_map($('#ahf-map'), center, zoom);
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
                console.log(errorThrown);
                console.log(textStatus);
            }
        });
    }

    // Fix for last menu item
    setTimeout(function () {
        var $last_item = $('#menu-top-menu .smart-menu-item:visible:last');
        if ($last_item.length > 0) $last_item.addClass('last-menu-item');
    }, 500);

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all page
                function isTouchDevice() {
                    return true === ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
                }

                checkLocation();

                /* Set location cookie if clicking on location menu */
                var $landingpages = ["/", "/act/", "/nsw/", "/tas/", "/sa/", "/wa/", "/nt/", "/vic/", "/qld/"];
                $("#locationsNav .menu-item > a").on("click", function (e) {
                    var $currentPage = window.location.pathname;
                    var $loc = $(this).attr("data-location");
                    if ($loc === 'national') {
                        setLocation("nta_pref_location", $loc, 365);
                    }
                    /*
                     if (($userloc === "") && ($.inArray($currentPage, $landingpages) === -1)) {
                     e.preventDefault();
                     setLocation("nta_pref_location", $loc, 365);
                     $('body').addClass('loc-'+$loc);
                     $('body').addClass('static-loc');
                     setTimeout(function(){ $('a#select-state').click(); }, 4000);
                     }
                     */
                });
                if ($(window).width() < 768) {
                    $("#locationsNavMob .menu-item > a").on("click", function () {
                        var $loc = $(this).attr("data-location");
                        if ($loc === 'national') {
                            setLocation("nta_pref_location", $loc, 365);

                        }

                    });
                }
                if ($('body').hasClass('new-loc')) {
                    if ($(window).width() > 767) {
                        $('#locationsNav').addClass('open-auto');
                        $('a#select-state').click();
                        $('#locationsNav').delay(3000).slideUp().attr('aria-expanded', 'false');
                        setTimeout(function () {
                            $('body').removeClass('new-loc');
                        }, 4000);
                    }
                }
                /* Make footer fixed on short pages */
                if ($(window).width() > 767) {
                    if ($('.wrapper-outer').height() < $(window).height()) {
                        $('footer.content-info').addClass('positioned');
                        $('.wrapper-outer').addClass('positioned');
                    } else {
                        $('.wrapper-outer').removeClass('positioned');
                        $('footer.content-info').removeClass('positioned');
                    }
                }

                // $('.gform_wrapper select:not([multiple="multiple"])').selectBoxIt({
                //     copyClasses: 'container',
                //     autoWidth: false,
                // });

                if ($('main .gform_wrapper').length) {
                    var footerFixer = window.setInterval(function () {
                        if ($(window).width() > 767) {
                            if ($('.wrapper-outer').height() < $(window).height()) {
                                $('footer.content-info').addClass('positioned');
                                $('.wrapper-outer').addClass('positioned');
                            } else {
                                $('.wrapper-outer').removeClass('positioned');
                                $('footer.content-info').removeClass('positioned');
                            }
                        }
                    }, 1000)
                }


                if ($('.eo-event-meta-link.expanding-link').length > 0) {
                    $('.eo-event-meta-link.expanding-link').click(function (e) {
                        $('ul.eo-event-meta').toggleClass('expanding');
                        $(this).hide();
                        $('.eo-event-meta-link.expanded-link').show();
                        e.preventDefault();
                    });
                    $('.eo-event-meta-link.expanded-link').click(function (e) {
                        $('ul.eo-event-meta').toggleClass('expanding');
                        $(this).hide();
                        $('.eo-event-meta-link.expanding-link').show();
                        e.preventDefault();
                    });
                }
                if ($('.tablepress').length > 0) {
                    var $count = 0;
                    var checkExist = setInterval(function () {
                        if ($('.dataTables_length select').length) {
                            $('.dataTables_wrapper select').selectBoxIt();
                            clearInterval(checkExist);
                        }
                        $count++;
                        if ($count > 20) {
                            clearInterval(checkExist);
                        }
                    }, 100);
                }

                // $(document).bind('gform_post_render', function () {
                //     $('.gform_wrapper select:not([multiple="multiple"])').selectBoxIt({
                //         copyClasses: 'container',
                //         autoWidth: false,
                //     });
                // });
                var $userloc = getLocation("nta_pref_location");
                if (($userloc !== "") && ($userloc !== 'national')) {
                    var $newHref, $href, $exploreHref;
                    $('.smart-menu-item > a').each(function () {
                        $href = $(this).attr('href');
                        if ($href.substr($href.length - 1) === '/') {
                            $newHref = $href.substr(0, $href.length - 1) + '-' + $userloc + '/';
                        } else {
                            $newHref = $href + '-' + $userloc + '/';
                        }
                        $(this).attr('href', $newHref);
                    });
                    if ($userloc !== 'national') {
                        $('nav ul li a').each(function () {

                            if ($(this).attr('href') !== undefined) {
                                if ($(this).attr('href').match(/explore\/?/g) || $(this).attr('href').match(/explore?/g)) {
                                    $exploreHref = $(this).attr('href') + '&state=' + $userloc;
                                    $(this).attr('href', $exploreHref);
                                }
                            }
                        });
                    }
                }

                if (isTouchDevice() === false) {
                    $('#location-btn[data-toggle="tooltip"]').tooltip({
                        trigger: 'hover focus'
                    });
                }
                $('[data-toggle="tooltip"]').not('#location-btn').tooltip({
                    trigger: 'hover focus'
                });
                $('[data-toggle="tooltip"]').not('#location-btn').on('click', function (e) {
                    e.preventDefault();
                });

                $('.acf-map').each(function () {
                    if ($(window).width() > 990) {
                        var parentHeight = $(this).parent().parent().parent().height();
                        $(this).css('height', parentHeight);
                    }
                    render_map($(this));
                });
                // image text boxes behaviours in mobile
                if ($(window).width() < 768) {
                    $('.expand-mob.with-col-4 .image-text.col-sm-4 h3').on('click', function (e) {

                        $(this).toggleClass('expanded');
                        $(this).next('.content-text-wrap').toggleClass('expanded').slideToggle();
                        e.preventDefault();
                    });
                    $('#menu-main-menu-1').masonry({
                        itemSelector: 'li.top-parent-item'
                    });
                }
                $('.expandbox h3> a').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).parent().hasClass('expanded')) {
                        ($(this).parent().removeClass('expanded'));
                        $(this).closest('#expandable').find('.list-inner.expanded').slideToggle().removeClass('expanded').attr('aria-expanded', 'false');
                    } else {
                        $(this).closest('#expandable').find('h3.expanded').removeClass('expanded');
                        $(this).parent().addClass('expanded');
                        $(this).closest('#expandable').find('.list-inner.expanded').slideToggle().removeClass('expanded').attr('aria-expanded', 'false');
                        $(this).closest('.list-item').find('.list-inner').slideToggle().addClass('expanded').attr('aria-expanded', 'true');
                    }
                });
                if ($('ul.related-collapse > li > a').length > 0) {
                    var $relatedItemID;
                    if ($(window).width() < 768) {
                        $('ul.related-collapse > li > a').each(function () {
                            $relatedItemID = $(this).attr('href');
                            if ($($relatedItemID).length > 0) {
                                $($relatedItemID).closest('.related-wrapper').appendTo($(this).parent());
                            }

                        });
                    }
                    $('ul.related-collapse > li.active').eq(0).find(' > a').click();
                }

                // sliders
                $('.slider-hero').slick({
                    dots: true,
                    infinite: true,
                });
                $('.slider-supporters').slick({
                    infinite: true,
                    slidesToShow: 5,
                    slidestoScroll: 1,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        }
                    ]
                });
                $('.slider-featured').slick({
                    infinite: true,
                    swipe: false
                });
                $('.slider-3-col').slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                autoplay: true,
                                autoplaySpeed: 4000
                            }
                        }
                    ]
                });
                $('.slider-teamblock').slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },

                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
                $('.slider-main').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: true,
                    adaptiveHeight: true,
                });
                $('.slider-nav').slick({
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    asNavFor: '#slider-main',
                    infinite: true,
                    //dots: true,
                    //centerMode: true,
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 5,
                            }
                        },
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 4,
                            }
                        }
                    ]
                });
                // set dynamic layout for AHF Event images
                if ($('.itboxes.imgs-2').length > 0) {
                    var $max_h = 0;
                    $('.itboxes.imgs-2 img').each(function () {
                        if ($(this).height() > $max_h) {
                            $max_h = $(this).height();
                        }
                    });
                    $('.itboxes.imgs-2 .image-text-wrap').height($max_h).css('line-height', $max_h + 'px');
                }
                var $sublink;
                $('#menu-main-menu-1.footer-nav .top-parent-item > .sub-menu > li > a').each(function () {
                    if ($(this).attr('href') === '#') {
                        $sublink = $(this).next('.sub-menu').find('> li > a ').attr('href');
                        $(this).attr('href', $sublink);
                    }
                });
            },

            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                $('a#select-state').on('click', function (e) {
                    e.preventDefault();
                    $('#locationsNav').slideToggle();
                    if ($('#locationsNav').hasClass('open-auto')) {
                        $('#locationsNav').removeClass('open-auto');
                    }

                    if ($('#locationsNav').attr('aria-expanded') === 'false') {
                        $('#locationsNav').attr('aria-expanded', 'true');
                    } else {
                        $('#locationsNav').attr('aria-expanded', 'false');
                    }
                });
                $('a#select-state-mob').on('click', function (e) {
                    e.preventDefault();
                    $('#locationsNavMob').slideToggle();

                    if ($('#locationsNavMob').attr('aria-expanded') === 'false') {
                        $('#locationsNavMob').attr('aria-expanded', 'true');
                    } else {
                        $('#locationsNavMob').attr('aria-expanded', 'false');
                    }
                });

                /*Popup Overlay
                -------------------------------------------------------------------*/
                /** Open Overlay 1/2 **/
                $(".open-page-overlay").on('click touchstart', function (e) {
                    e.preventDefault();
                    open_page_overlay($(this));
                    $(this).addClass('open');
                });

                /** Closing Overlay 2/2 **/
                /* When clicking Close Button */
                $('.close_overlay').on('click touchstart', function (e) {
                    e.preventDefault();
                    close_page_overlay();
                });

                /* When Clicking Out */
                var page_overlay_inside_element = $('.page-overlay:not(.404-page) .page-overlay-content .page-overlay-content-main');
                $('.page-overlay:not(.404-page)').bind("click touchstart", function (e) {
                    if ($(e.target).closest(page_overlay_inside_element).length === 0) {
                        close_page_overlay();
                    }
                });

                /* When Pressing ESC */
                $(document).on('keydown', function (e) {
                    if (e.keyCode === 27) {
                        close_page_overlay();
                    }
                });

                /* Popup Overlay Functions
                ------------------------------------------------------------------------------*/
                function open_page_overlay(thisObj) {
                    var data_page_overlay = $(thisObj).attr('data-open-target');
                    var page_overlay = '.page-overlay[data-page-overlay="' + data_page_overlay + '"]';
                    $('body').addClass('page-overlay-actived');
                    setTimeout(function () {
                        $.scrollLock(true);
                        //$('html,body').addClass('page-overlay-displayed');
                        $('body').addClass(data_page_overlay);
                        $(page_overlay).addClass("actived");
                        /* Custom Effect 1/2 */
                        $('#mainmenu').addClass('invisible');
                        $('#mainmenu').removeClass('visible');
                        $('.logo').addClass('overlay-active');
                        $('#wrapper').css('position', 'initial');
                    }, 10);
                    setTimeout(function () {
                        $(page_overlay).addClass("displayed");
                        $('#header').addClass("page-overlay-active");
                    }, 50);
                    setTimeout(function () {
                        $(page_overlay + " .page-overlay-content").addClass("displayed");
                        $('.page-overlay .get-focus-active').focus();
                    }, 70);
                }
                function close_page_overlay() {
                    //$('.close_overlay').addClass('active');
                    //$( ".open-page-overlay" ).removeClass("active");
                    var data_page_overlay = $('.page-overlay.displayed:not(.404-page)').attr('data-page-overlay');

                    $.scrollLock(false);

                    setTimeout(function () {
                        $('.page-overlay-content').removeClass("displayed");
                    }, 10);
                    setTimeout(function () {
                        $('.page-overlay:not(.404-page)').removeClass("displayed");
                        $('#header').removeClass("page-overlay-active");
                        $(".btn-trigger").removeClass('open');
                        /* Custom Effect 2/2*/
                        $('#mainmenu').removeClass('invisible');
                        $('#mainmenu').addClass('visible');
                        $('.logo').removeClass('overlay-active');
                        $('#wrapper').css('position', 'relative');
                    }, 100);
                    setTimeout(function () {
                        $('.page-overlay:not(.404-page)').removeClass("actived");
                        //$('html,body').removeClass('page-overlay-displayed');
                    }, 400);
                    setTimeout(function () {
                        $('body').removeClass(data_page_overlay);
                        $('body').removeClass('page-overlay-actived');
                    }, 700);
                }

                // Close alert message if cookie exist
                // ==================================================
                if (!getCookie('alert_message_cookie_seen')) $('.alert-message').addClass('active');

                // Close alert message on icon-close click
                // ==================================================
                $('.alert-message .icon-close').click(function () {
                    $('.alert-message').slideUp().removeClass('active');
                    setCookie('alert_message_cookie_seen', true, 365);
                });

                /* Collage Container LightBox
                -------------------------------------------------------------------*/
                if ($('.collage-container').length > 0) {
                    $('.collage-container').slickLightbox({
                        //itemSelector: '.lightbox-link',
                        caption: 'caption'
                    }).on({
                        'show.slickLightbox': function () {
                            open_page_overlay();
                            //console.log('A `show.slickLightbox` event triggered.');
                        },
                        // 'shown.slickLightbox': function(){ console.log('A `shown.slickLightbox` event triggered.'); },
                        'hide.slickLightbox': function () {
                            close_page_overlay();
                            //console.log('A `hide.slickLightbox` event triggered.');
                        },
                        //'hidden.slickLightbox': function(){ console.log('A `hidden.slickLightbox` event triggered.'); }
                    });
                };

                /* Places Gallery Container LightBox
               -------------------------------------------------------------------*/
                if ($('.places-template__gallery-container').length > 0) {
                    $('.places-template__gallery-container').slickLightbox({
                        //itemSelector: '.lightbox-link',
                        caption: 'caption'
                    }).on({
                        'show.slickLightbox': function () {
                            open_page_overlay();
                            //console.log('A `show.slickLightbox` event triggered.');
                        },
                        // 'shown.slickLightbox': function(){ console.log('A `shown.slickLightbox` event triggered.'); },
                        'hide.slickLightbox': function () {
                            close_page_overlay();
                            //console.log('A `hide.slickLightbox` event triggered.');
                        },
                        //'hidden.slickLightbox': function(){ console.log('A `hidden.slickLightbox` event triggered.'); }
                    });

                }

                // Places Gallery Button
                $('.places-template__gallery-button button').on('click', function (e) {
                    e.preventDefault();

                    $('.places-template__gallery-item:first-child a', '#places__gallery').click();
                });




            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                $('body.home #locationsNav').addClass('highlight-auto');
                var $userloc = getLocation("nta_pref_location");
                if ($userloc === '') {
                    if ($(window).width() > 767) {
                        $('body').addClass('no-loc');
                        $('#locationsNav').addClass('open-auto');
                    }
                }
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $('a#select-state').click();
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'explore': {
            init: function () {
                // JavaScript to be fired on the explore page
                var $stateParam = '';
                var $contentParam = '';
                var $taxParam = '';
                var $sortParam = '';
                var $prefix = '&';
                var $firstParam = 1;
                var $expandTarget = '';
                var $firstVisit = 1;
                var $parentType, $parentId, $currentCheckbox;
                var $currentState = getLocation("nta_pref_location");
                var $currentSearch = location.search;
                $currentSearch = $currentSearch.substring(1);

                var $url = 'http://' + window.location.hostname + '/explore/';

                $('#selectsortoptions').selectBoxIt();
                var $allParams = parseQuery();
                // $currentSearch.replace(/([^=&]+)=([^&]*)/g, function(m, key, value) {
                //     $allParams[decodeURIComponent(key)] = decodeURIComponent(value);
                // });
                $('.view-options .view-grid').on('click', function () {
                    $('#explore-content-wrapper').removeClass('explore-list');
                });
                $('.view-options .view-list').on('click', function () {
                    $('#explore-content-wrapper').addClass('explore-list');
                });
                // Update all fields with the current search parameters.
                if ($currentSearch) {
                    $('input[name=s]').val($allParams['keyword']);
                    $.each($allParams, function (k, v) {
                        if (k == '') {
                            return;
                        }
                        if (Array.isArray(v) && k !== 'state') {
                            v.forEach(function (j) {
                                var cb = $('input[type="checkbox"][name="' + k + '"][value="' + j + '"]');
                                if (cb.length) {
                                    cb.prop('checked', true);
                                    cb.closest('.group-expand').addClass('expanded');
                                    cb.closest('.input-label-group').addClass('active');
                                }
                            });
                        }
                        if (k == 'content') {
                            var contents = v.split('+');
                            contents.forEach(function (vc) {
                                $('#' + vc).prop('checked', true);
                            })
                        }
                        if ((k === 'content') && (v === 'educationprograms')) {
                            $('#sortoptions').addClass('with-targetage');
                        }
                        if ((k === 'content') && (v === 'educationprograms')) {
                            $('#sortoptions').addClass('with-targetage');
                        }
                        if ((k === 'content') && (v === 'event')) {
                            $('#sortoptions').addClass('with-event');
                        }
                        if (k === 'state') {
                            var cb = $('#' + v).prop('checked', true);
                            cb.closest('.group-expand').addClass('expanded');
                            $('h3.wrapper-state').addClass('active');
                        }
                        // if ((k === 'orderby') || (k === 'date_range') || (k === 'target_age')) {
                        //     $('select#selectsortoptions').data("selectBox-selectBoxIt").selectOption(v);
                        // }
                        if ($allParams['content'] && $allParams['content'] == 'ahf_event') {
                            $('input[name=' + k + ']').val(v);
                        }
                        if (k === 'ahf_date') {
                            var $newahf_date = v.split("-").reverse().join("/");
                            $('input#ahf_datepicker').val($newahf_date);
                        }
                    });
                }
                //focus search field wrapper
                $('#keyword-search').focus(function () {
                    $(this).parent().addClass('focused');
                }).blur(function () {
                    $(this).parent().removeClass('focused');
                });
                //show hide taxonomy lists
                $('form#filters .group-expand-title a').on('click', function (e) {

                    // up to here
                    console.log("test123");
                    e.preventDefault();
                    $(this).closest('.input-label-group, h3.group-expand-title').toggleClass('expanded');
                    $expandTarget = $(this).attr("href");

                    $($expandTarget).slideToggle(function () {
                        $($expandTarget).toggleClass('expanded');
                    });
                });
                $('#filter-content #filter-state-expand > input[type="checkbox"]').change(function () {
                    if ($(this).is(":checked")) {
                        $('#filter-state h3').addClass('active');
                    } else {
                        $('#filter-state h3').removeClass('active');
                    }
                });
                $('#filter-content .group-expand.content-tax > input[type="checkbox"]').change(function () {
                    $parentType = $(this).attr('data-parent');

                    $parentId = $parentType.replace('_type', 's');

                    if ($parentType === 'education_type') {
                        $parentId = 'educationprograms';
                    }
                    if ($parentType === 'event_type') {
                        $parentId = 'event';
                    }

                    if ($parentType === 'blog_type') {
                        $parentId = 'blog';
                    }

                    if ($(this).is(":checked")) {
                        $('input#' + $parentId).prop('checked', true).parent('.input-label-group').addClass('active');
                    }

                });
                $('input[type="checkbox"][name="content"]').change(function () {
                    var $currentCheckbox = $(this).parent().next('.group-expand.content-tax').find('input[type="checkbox"]').prop('checked', false);
                    if ($(this).is(":checked")) {
                        $(this).parent('.input-label-group').addClass('active');
                    } else {
                        $(this).parent('.input-label-group').removeClass('active');
                    }

                    if (($('input[type="checkbox"][name="content"]:checked').length === 1) && ($('input[type="checkbox"][name="content"]:checked').attr('id') === 'educationprograms')) {
                        $('#sortoptions').addClass('with-targetage');
                    } else {

                        if ($('select#selectsortoptions option:selected').attr('data-set') === 'target_age') {
                            $('select#selectsortoptions').val('none');
                        }
                        $('#sortoptions').removeClass('with-targetage');
                    }
                    if (($('input[type="checkbox"][name="content"]:checked').length === 1) && ($('input[type="checkbox"][name="content"]:checked').attr('id') === 'event')) {
                        $('#sortoptions').addClass('with-event');
                    } else {
                        if ($('select#selectsortoptions option:selected').attr('data-set') === 'date_range') {
                            $('select#selectsortoptions').val('none');
                        }
                        $('#sortoptions').removeClass('with-event');
                    }
                });
                $('select#selectsortoptions').change(function () {
                    if (($('input[type="checkbox"][name="content"]:checked').length > 0) && ($('select#selectsortoptions').val() !== 'none')) {
                        $('form#filters').submit();
                    }

                });
                // Explore page submit function

                $('form#filters').submit(function (e) {
                    e.preventDefault();
                    if ($('#ahf-explore-btns').length > 0) {
                        $('form#ahf-filters').submit();
                    } else {
                        if ($('#filter-state input[type="checkbox"]:checked').length > 0) {
                            $('#filter-state input[type="checkbox"]:checked').each(function (i) {
                                $stateParam += $(this).val();
                                if (i < ($('#filter-state input[type="checkbox"]:checked').length - 1)) {
                                    $stateParam += '+';
                                }
                            });
                        }
                        if ($('#filter-content input[name="content"][type="checkbox"]:checked').length > 0) {
                            $('#filter-content input[name="content"][type="checkbox"]:checked').each(function (i) {
                                $contentParam += $(this).val();
                                if (i < ($('#filter-content input[name="content"][type="checkbox"]:checked').length - 1)) {
                                    $contentParam += '+';
                                }
                            });
                        }
                        var $typetax = '';
                        $('#filter-content .group-expand.content-tax').each(function () {
                            $this = $(this);
                            if ($this.find('input[type="checkbox"]:checked').length > 0) {
                                $taxParam = '';
                                $typetax = $this.find('input[type="checkbox"]:first-child').attr("name");
                                $this.find('input[type="checkbox"]:checked').each(function (j) {
                                    $taxParam += $(this).val();
                                    if (j < ($this.find('input[type="checkbox"]:checked').length - 1)) {
                                        $taxParam += '+';
                                    }
                                });

                                if ($firstParam) {
                                    $prefix = '?';
                                    $firstParam = 0;
                                } else {
                                    $prefix = '&';
                                }
                                $url += $prefix + $typetax + '=' + $taxParam;
                            }
                        });

                        var $keyword = $('input.keyword-field').val();
                        if ($contentParam !== '') {
                            if ($firstParam) {
                                $prefix = '?';
                                $firstParam = 0;
                            } else {
                                $prefix = '&';
                            }
                            $url += $prefix + 'content=' + $contentParam;
                        }
                        if ($stateParam !== '') {
                            if ($firstParam) {
                                $prefix = '?';
                                $firstParam = 0;
                            } else {
                                $prefix = '&';
                            }
                            $url += $prefix + 'state=' + $stateParam;
                        }
                        if ($keyword) {
                            if ($firstParam) {
                                $prefix = '?';
                                $firstParam = 0;
                            } else {
                                $prefix = '&';
                            }
                            $url += $prefix + 'keyword=' + $keyword;
                        }
                        if (($('select#selectsortoptions').val() !== 'none') && ($('input[type="checkbox"][name="content"]:checked').length > 0)) {
                            $prefix = '&';
                            var $dataSet = $('select#selectsortoptions option:selected').attr('data-set');
                            var $dataValue = $('select#selectsortoptions').val();


                            $url += $prefix + $dataSet + '=' + $dataValue;
                        }
                        window.location = $url;
                    }
                });

                $('form#ahf-filters').submit(function (e) {
                    e.preventDefault();
                }); //end ahf_filter search

                // Toggle map view on Explore page.

                if ($('#show-map').length) {
                    $('#show-map').on('click', function () {
                        $('#explore-content-wrapper').parent().addClass('with-map');
                        $('#ahf-map').each(function () {
                            if ($(this).hasClass('partial-list')) {
                                $('#not-all-items').show();
                            }
                            $(this).removeClass('hidden');
                            $('.with-map #exploremain').addClass('hidden');

                            if ($map_loaded === 0) {
                                render_map($(this), null, null, $allParams['region-lat'], $allParams['region-lng']);
                            } else {
                                google.maps.event.trigger(map, 'resize');
                            }
                        });
                    });
                }
                $('#show-grid').on('click', function () {
                    $('#not-all-items').hide();
                    $('#ahf-map').addClass('hidden');
                    $('.with-map #exploremain').removeClass('hidden');
                    $('#explore-content-wrapper').parent().removeClass('with-map');
                });
                if (($('#chosen_location').length > 0) && ($('input#region-search').val() !== '')) {
                    $('#chosen_location').text($('input#region-search').val());
                }
                // show hide filters on mobile
                if ($(window).width() < 768) {
                    $('form#filters > h2').on('click', function () {
                        $(this).toggleClass('expanded');
                        $('form#filters #explore-sidebar-wrapper').slideToggle();
                    });
                }
                // Setup ajax pagination
                var $count = 2;
                var $nextpage;
                var $total = $('#exploremain').attr('data-total');
                $('#loadmore').on('click', function (e) {
                    e.preventDefault();
                    if ($count >= $total) {
                        $('#loadmore').hide();
                    }
                    if ($('#loadmore').hasClass('with-distance')) {
                        $('.explore-main.hidden').eq(0).fadeIn().removeClass('hidden');
                    } else {
                        $nextpage = $('.prev-posts-link > a').last().attr('href');

                        $.get($nextpage, function (data) {
                            $(data).find("#exploremain").hide().appendTo("#new-projects").fadeIn();
                            $('.prev-posts-link > a').last().attr('href', $(data).find('.prev-posts-link > a').last().attr('href'));
                        });
                    }

                    $count++;
                });

                $(document).ajaxStart(function () {
                    $("#spinner").show();
                })

                    .ajaxStop(function () {
                        $("#spinner").fadeOut();
                    });
            }
        }
    };


    /* Accordion
    -------------------------------------------------------------------*/
    $('.accordion-box:not(.multi-open) .accordion-databox').each(function () {
        var $accordion = $(this),
            $accordionTrigger = $accordion.find('.accordion-trigger'),
            $accordionDatabox = $accordion.find('.accordion-data');

        $accordionTrigger.on('click', function (e) {
            var $this = $(this);
            var $accordionData = $this.next('.accordion-data');
            if ($accordionData.is($accordionDatabox) && $accordionData.is(':visible')) {
                $this.parent().removeClass('open');
                $accordionData.slideUp(400);
                e.preventDefault();
            } else {
                $accordionTrigger.parent().removeClass('open');
                $this.parent().addClass('open');

                $accordionDatabox.slideUp(400);
                $accordionData.slideDown(400, function () {
                    // Animation complete.
                    // Just for mobile to scroll back to the top of the page
                    // if ($(".d-md-block").css("display") !== "block") {
                    //     $('html,body').animate({
                    //         scrollTop: $this.offset().top
                    //     }, 50);
                    // }
                });
            }
        });
    });

    /* Accordion - Multi Open
    -------------------------------------------------------------------*/
    $('.accordion-box.multi-open .accordion-row .accordion-trigger').each(function () {
        var $content = $(this).closest('div').find('.accordion-data');

        $(this).bind('click tap', function (e) {
            e.preventDefault();
            if (!$content.is(':animated')) {  //rigth way to ask if...
                if (!$(this).closest('div').hasClass("open")) {
                    $(this).closest('div').addClass('open');
                } else {
                    $(this).closest('div').removeClass('open');
                }
            }
            $content.not(':animated').slideToggle(400);
        });
    });

    /* Accordion - First Open option
    -------------------------------------------------------------------*/
    $('.accordion-box.first-open').each(function () {
        $(this).find('.accordion-row').first('accordion-row').addClass('open');
        $(this).find('.accordion-row').first('accordion-row').find('div').show();
    });

    /* Sticky Sidebar
    -------------------------------------------------------------------*/
    var sticky_sidebar = $(".sticky-sidebar");

    var sticky_banner_image = $(".events-template__banner-image-wrap");

    var sticky_more_info = $(".events-template__banner-image .places-template__details");

    if (sticky_banner_image.length) {
        var banner_height = sticky_banner_image.position().top + sticky_banner_image.outerHeight(true);
        sticky_more_info.css('top', banner_height);
    }

    // if (sticky_sidebar.length) {
    //     var sticky = sticky_sidebar.offset().top;
    //     var main_blocks = $(".blocks-template");

    //     // console.log($(".events-template").offset().top + $(".events-template").outerHeight(true));
    //     window.onscroll = function () {
    //         var article_container = $(".article-section-wrapper").offset().top + $(".article-section-wrapper").outerHeight();
    //         var content_container = $(".entry-content").offset().top + $(".entry-content").outerHeight();
    //         // var content_container = $(".entry-content").offset().top + $(".entry-content").outerHeight();
    //         var accordion_container = $(".events-template__accordions").offset().top;
    //         var offset_sticky = sticky + sticky_sidebar.outerHeight(true);
    //         var sidebarBottom = sticky_sidebar.offset().top + sticky_sidebar.height();

    //         if (window.pageYOffset > sticky) {
    //             sticky_sidebar.addClass('sticky');
    //         }
    //         else {
    //             sticky_sidebar.removeClass('sticky');
    //         }
    //         if (!$(".events-template__accordions").length) {
    //             if (sticky_more_info.length) {
    //                 if (content_container < (window.pageYOffset + (sticky_more_info.height() + 150)) && sticky_sidebar.hasClass("sticky")) {
    //                     sticky_sidebar.addClass("sticky-transition");
    //                 } else {
    //                     sticky_sidebar.removeClass("sticky-transition");
    //                 }
    //             } else {
    //                 if (content_container < (window.pageYOffset + 150) && sticky_sidebar.hasClass("sticky")) {
    //                     sticky_sidebar.addClass("sticky-transition");
    //                 } else {
    //                     sticky_sidebar.removeClass("sticky-transition");
    //                 }
    //             }

    //         } else {
    //             if (sticky_more_info.length) {
    //                 if (accordion_container < (window.pageYOffset + (sticky_more_info.height() + 150)) && sticky_sidebar.hasClass("sticky")) {
    //                     sticky_sidebar.addClass("sticky-transition");
    //                 } else {
    //                     sticky_sidebar.removeClass("sticky-transition");
    //                 }
    //             } else {
    //                 if (accordion_container < (window.pageYOffset + 150) && sticky_sidebar.hasClass("sticky")) {
    //                     sticky_sidebar.addClass("sticky-transition");
    //                 } else {
    //                     sticky_sidebar.removeClass("sticky-transition");
    //                 }
    //             }

    //         }
    //     };

    // }


    /* Collage Gallery
    -------------------------------------------------------------------*/

    // Declaring variables
    var collage_container = ".collage-container";
    var collage_element = ".cell";
    var collage_gutter = 20 // Number of pixels

    // Initializing Collage gallery with Freewall library
    $(collage_container).each(function () {

        var currentCollage = this; // To work inside functions ( Scope )

        var wall = new Freewall(currentCollage);
        wall.reset({
            selector: collage_element,
            animate: false,
            cellW: 140,
            cellH: 140,
            onResize: function () {
                // wall.refresh($(window).width() - 30, $(window).height() - 30);
                wall.fitHeight($(window).height() - 150);
                if ($(".d-lg-block").css("display") === "block") {
                    // console.log('desktop');
                    wall.fitWidth();
                } else {
                    wall.destroy();
                    // console.log('mobileXs');
                }
                fillLastRow(currentCollage);
            }
        });

        wall.container.find('.collage-container .cell').load(function () {
            wall.fitHeight($(window).height() - 150);
        })

        // wall.fitWidth();
        // caculator width and height for IE7;
        // wall.fitZone($(window).width() - 30 , $(window).height() - 30);

        // Adding custom function to fill last row

        setTimeout(function () {
            fillLastRow(currentCollage);
        }, 10);  // If transtion is On, change to 300 at least

    });

    // Custom function to get fill the last row
    function fillLastRow(collage) {

        var $collage = $(collage);

        // *** Get Container width ***
        var containerWidth = $collage.width();

        // *** Get lastRow width ***
        // Declaring variables
        var allBlock = $collage.find(collage_element);
        var maxItemTop = allBlock.last().css('top');
        var lastRowWidth = 0;

        // Looping elements
        var firstIteration = true;  // To get the fisrt iteration of the last row
        allBlock.each(function (index, item) {
            var $item = $(item);
            if ($item.css("top") == maxItemTop) {
                if (firstIteration) {
                    lastRowWidth += parseFloat($item[0].getBoundingClientRect().width);
                } else {
                    lastRowWidth += collage_gutter + parseFloat($item[0].getBoundingClientRect().width);
                }
                firstIteration = false;
            }
        });

        // *** If Container width if bigger than lastRowWidth make the last element full width ***
        if (containerWidth > lastRowWidth) {

            // Get remaining gap
            var remaining_gap = containerWidth - lastRowWidth;

            // Get last element width
            var last_element_width = allBlock.last()[0].getBoundingClientRect().width;

            //  Update last element width = Adding Container width minus last row width
            allBlock.last().css('width', last_element_width + remaining_gap);
        }

    }

    /*
    ** Function for read more button
    */
    var $button = $('.text-overlay-detailed-block div[id*="text-overlay-detailed-"] .text-overlay-read-more');
    $button.on("click", function (e) {
        e.preventDefault();
        var $button_text = $(this).text();
        var $more_content = $(this).parent().find(".text-overlay-more-content");
        var $current_button = $(this).parent().find(".text-overlay-read-more");

        if ($button_text == 'Read More +') {
            $current_button.text("Read Less -");
            $more_content.slideDown();
        } else {
            $current_button.text("Read More +");
            $more_content.slideUp();
        }
    });

    /* Tab Content box
    **
    ---------------------------------------------------------------------*/
    var tabBlockElement = $('.jq-tabs');
    $(tabBlockElement).each(function () {
        var $this = $(this),
            tabTrigger = $this.find(".tabnav li"),
            tabContent = $this.find(".tabcontent");
        var textval = [];
        tabTrigger.each(function () {
            textval.push($(this).text());
        });
        $this.find(tabTrigger).first().addClass("active");
        $this.find(tabContent).first().show();

        $(tabTrigger).on('click', function () {
            if (!$(this).hasClass('active')) {
                $(tabContent).hide().removeClass('visible');
                var activeTab = $(this).find("a").attr("data-rel");
                $this.find('[data-rel=' + activeTab + ']').fadeIn('normal').addClass('visible');
            }
            $(tabTrigger).removeClass("active");
            $(this).addClass("active");

            return false;
        });

        var responsivetabActive = function () {
            if ($(".d-lg-block").css("display") !== "block") {
                if (!$this.find('.tabMobiletrigger').length) {
                    $(tabContent).each(function (index) {
                        $(this).before("<h5 class='tabMobiletrigger'>" + textval[index] + "</h5>");
                        $this.find('.tabMobiletrigger:first').addClass("rotate");
                    });
                    $this.find('.tabMobiletrigger').on('click', function () {
                        var tabAcoordianData = $(this).next('.tabcontent');
                        if ($(tabAcoordianData).is(':visible')) {
                            $(this).removeClass('rotate');
                            $(tabAcoordianData).slideUp('normal');
                            //return false;
                        } else {
                            $this.find('.tabMobiletrigger').removeClass('rotate');
                            $(tabContent).slideUp('normal');
                            $(this).addClass('rotate');
                            $(tabAcoordianData).not(':animated').slideToggle('normal');
                        }
                        return false;
                    });
                }

            } else {
                if ($('.tabMobiletrigger').length) {
                    $('.tabMobiletrigger').remove();
                    tabTrigger.removeClass("active");
                    $this.find(tabTrigger).removeClass("active").first().addClass('active');
                    $this.find(tabContent).hide().first().show();
                }
            }
        };
        $(window).on('resize', function () {
            if (!$this.hasClass('only-tab')) {
                responsivetabActive();
            }
        }).resize();
    });

    /*
     ** Function for making the dropdown on edge.
     ---------------------------------------------------------------------*/
    $(".header-primary ul.nav.navbar-nav li").on('mouseenter mouseleave', function (e) {
        if ($('.dropdown-menu', this).length) {
            var el = $('.dropdown-menu:first', this);
            var off = el.offset();
            var left = off.left;
            var width = el.width();
            var documentHeight = $(".wrapper-outer").height();
            var documentWidth = $(".wrapper-outer").width();

            var isEntirelyVisible = (left + width <= documentWidth);

            if (!isEntirelyVisible) {
                $('.dropdown-menu').addClass('edge');
            } else {
                $('.dropdown-menu').removeClass('edge');
            }
        }
    });
    /*
    ** Function to get height of block and match it to each.
    ---------------------------------------------------------------------*/
    function matchHeight(el) {
        $(el).css('height', 'auto');
        var maxHeight = 0;
        $(el).each(function () {
            if ($(this).outerHeight() > maxHeight) {
                maxHeight = $(this).outerHeight();
            }
        });
        maxHeight = maxHeight + 'px';
        $(el).css('height', maxHeight);
    }


    // On load and resize
    // Load
    $(window).load(function () {
        matchHeight('.text-overlay-detailed-block .image-text .text-overlay-block .text-overlay-tile-item');
    });
    // Resize
    $(window).resize(function () {
        matchHeight('.text-overlay-detailed-block .image-text .text-overlay-block .text-overlay-tile-item');
    });


    var ie_version = detectIE();
    if (ie_version >= 9 && ie_version <= 11) {
        $('html').addClass("ie-compatible");
    }
    if (ie_version == 10) {
        $('html').addClass("ie-compatible-10");
    }
    if (ie_version >= 10) {
        $('html').addClass("ie-edge-compatible");
    }

    if ($('html').hasClass('ie-compatible') ||
        $('html').hasClass('ie-compatible-10') ||
        $('html').hasClass('ie-edge-compatible')) {
        $('.hero-single-image-container').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
        // console.log('oo');
    } else {
        //   console.log('hinde');
    }

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
